import {useEffect} from "react";
import * as THREE from "three";

import globeImage from "lib/assets/img/globeImage.jpg";
import bumpImage from "lib/assets/img/bumpImage.jpg";
import backgroundImage from "lib/assets/img/backgroundImage.png";
import materialImage from "lib/assets/img/materialImage.png";

const globeMaterial = new THREE.MeshPhongMaterial();
globeMaterial.bumpScale = 10;
new THREE.TextureLoader().load(materialImage, (texture) => {
    globeMaterial.specularMap = texture;
    globeMaterial.specular = new THREE.Color("grey");
    globeMaterial.shininess = 7.5;
});

// Custom solar material
const solarMaterial = new THREE.MeshLambertMaterial({
    color: "#000000",
    opacity: 0.5,
    transparent: true,
});
const sun = new THREE.DirectionalLight(0xffffff, 1);
const useRenderGlobe = (
    groundstationData,
    globeEl,
    setGlobeRadius,
    showAxis,
    setGroundstations,
) => {
    useEffect(() => {
        // Ambient light
        const ambient = new THREE.AmbientLight(0x404040, 0.5);

        globeEl.current.scene().add(sun);
        globeEl.current.scene().add(ambient);
    }, []);

    class UnionFind {
        constructor(elements) {
            this.parent = {};
            elements.forEach((e) => (this.parent[e] = e));
        }

        union(a, b) {
            this.parent[this.find(b)] = this.find(a);
        }

        find(a) {
            if (this.parent[a] !== a) {
                this.parent[a] = this.find(this.parent[a]);
            }
            return this.parent[a];
        }
    }

    const determineOrientation = (groundStations, proximity) => {
        const uf = new UnionFind(groundStations.map((gs) => gs.gs_name));

        groundStations.forEach((gs1, i) => {
            for (let j = i + 1; j < groundStations.length; j++) {
                const gs2 = groundStations[j];
                if (
                    Math.abs(gs1.lat - gs2.lat) <= proximity
                    && Math.abs(gs1.lng - gs2.lng) <= proximity
                ) {
                    uf.union(gs1.gs_name, gs2.gs_name);
                }
            }
        });

        const groups = {};
        groundStations.forEach((gs) => {
            const parent = uf.find(gs.name);
            if (!groups[parent]) {
                groups[parent] = [];
            }
            groups[parent].push(gs);
        });

        Object.values(groups).forEach((group) => {
            group.sort((a, b) => b.lat - a.lat);
            group[0].dotOrientation = "top";
            for (let i = 1; i < group.length; i++) {
                group[i].dotOrientation = "bottom";
            }
        });

        return groundStations;
    };

    const cleanGsData = (gsData) => {
        // group data by lat and lng
        const groupedData = gsData.reduce((acc, gs) => {
            const key = `${Math.round(gs.latitude)} - ${Math.round(
                gs.longitude,
            )}`;
            if (!acc[key]) acc[key] = [];
            acc[key].push(gs);
            return acc;
        }, {});
        // iterate through grouped data and merge appropriate data
        return Object.values(groupedData).map((group) => {
            if (group.length === 1) {
                return {
                    name: group[0].gs_name,
                    lat: group[0].latitude,
                    lng: group[0].longitude,
                    dotOrientation: "bottom",
                    size:
                        group[0].gs_name.length <= 15
                            ? 1.75
                            : 2
                              - 1
                                  / (1
                                      + Math.pow(
                                          Math.E,
                                          -1 * (group[0].gs_name.length - 15),
                                      )),
                };
            }

            const label = group.map((gs) => gs.gs_name)[0];
            return {
                name: label,
                lat: group[0].latitude,
                lng: group[0].longitude,
                dotOrientation: "bottom",
                size:
                    label.length <= 15
                        ? 1.75
                        : 2
                          - 1 / (1 + Math.pow(Math.E, -1 * (label.length - 15))),
            };
        });
    };

    useEffect(() => {
        if (groundstationData) {
            const cleanedGsData = cleanGsData(groundstationData);
            const orientatedGsData = determineOrientation(cleanedGsData, 5);
            setGroundstations(
                orientatedGsData.map((gs) => {
                    return {
                        ...gs,
                        dotColor: "#FFFFFF", // Must be a Web Safe Color
                        dotRadius: 0.5,
                    };
                }),
            );
        } else {
            setGroundstations([]);
        }
    }, [groundstationData]);

    const onGlobeReady = useEffect(() => {
        // setGroundsites(groundsites); // first need to calculate groundsites
        setGlobeRadius(globeEl.current.getGlobeRadius());

        globeEl.current.pointOfView({altitude: 3.5}); // Zoom to default zoom
        globeEl.current.controls().enableDamping = true;
        globeEl.current.controls().dampingFactor = 0.2;
    }, []);

    useEffect(() => {
        if (showAxis) {
            globeEl.current.scene().add(new THREE.AxesHelper(150));
        } else {
            // remove axis
            globeEl.current.scene().children.forEach((child) => {
                if (child.type === "AxesHelper") {
                    globeEl.current.scene().remove(child);
                }
            });
        }
    }, [showAxis]);

    return {
        onGlobeReady,
        globeImage,
        bumpImage,
        backgroundImage,
        globeMaterial,
        solarMaterial,
        sun,
    };
};

export default useRenderGlobe;
